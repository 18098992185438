<template>
  <div class="component-ground">
    <nav :class="{ opened: state.opened }">
      <div class="container">
        <div class="wrapper">
          <router-link to="/" class="brand font-ubuntu">
            <img src="/assets/img/img.logo.svg" />
            <span>frontree</span>
          </router-link>
          <ul>
            <li v-for="(n, idx) in defines.navs" :class="{ active: state.nav === n.name }" :key="idx">
              <router-link :to="`/${n.name}`" @click="toggle(false)">{{ n.title }}</router-link>
            </li>
          </ul>
          <div class="account" title="로그인">
            <button type="button" class="btn btn-outline-light">Login</button>
          </div>
          <div class="toggle d-inline-block d-sm-none" @click="toggle">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </nav>
    <article>
      <router-view />
    </article>
    <footer :style="{ background: $route.path === '/' ? '#f7f7f7' : '' }">
      <div class="container clearfix">
        <div class="copyright">&copy; 2022. Frontree. All rights reserved.</div>
        <a class="email" href="mailto:africalibrary21@gmail.com">africalibrary21@gmail.com</a>
      </div>
    </footer>
    <button class="climb btn color-point border-point" ref="climbBtnRef" @click="climb()">
      TOP
    </button>
    <Video v-if="$store.state.video" />
  </div>
</template>
<script>
import { reactive } from "@vue/reactivity";
import defines from "../defines.json";
import Video from "@/components/Video.vue";

export default {
  components: { Video },
  setup() {
    const state = reactive({
      nav: {},
      opened: false,
    });

    const toggle = (val) => {
      if (typeof val === "boolean") {
        state.opened = val;
      } else {
        state.opened = !state.opened;
      }
    };

    const activate = () => {
      state.nav = "";

      if (location.pathname && location.pathname.length > 1) {
        const arr = location.pathname.split("/");

        if (arr.length > 1) {
          const nav = defines.navs.find((n) => n.name === arr[1]);

          if (nav) {
            state.nav = nav.name;
            document.title = nav.title + " - " + process.env.VUE_APP_NAME_KO;
            return;
          }
        }
      }

      document.title = process.env.VUE_APP_TITLE_KO;
    };

    return {
      state,
      defines,
      toggle,
      activate,
    };
  },
  watch: {
    $route() {
      window.scrollTo(0, 0);
      this.activate();
    },
  },
};
</script>
<style lang="scss">
.component-ground {
  position: relative;

  > nav {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    background: $colorNav;
    z-index: 3;

    > .container {
      position: relative;

      > .wrapper {
        position: relative;
        padding: 10px 0;
        padding-left: 200px;

        .brand {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 25px;
          cursor: pointer;
          text-decoration: none;
          text-align: center;
          padding: 21px 0;
          white-space: nowrap;

          > img {
            width: 33px;
            height: 33px;
            margin-right: 10px;
            border-radius: 50%;
            vertical-align: middle;
          }

          span {
            color: #fff;
            display: inline-block;
            vertical-align: middle;
          }
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          // text-align: right;

          li {
            display: inline-block;
            margin: 0 5px;

            > a {
              color: inherit;
              padding: 19px 10px;
              cursor: pointer;
              color: #fff;
              display: inline-block;
              text-decoration: none;

              &:hover {
                font-weight: bold;
              }
            }

            &.active {
              font-weight: bold;
            }
          }
        }

        .account {
          position: absolute;
          top: 50%;
          right: 0;
          cursor: pointer;
          transform: translateY(-50%);
        }

        .toggle {
          position: absolute;
          top: 50%;
          right: 15px;
          height: 18px;
          width: 25px;
          padding: 0;
          transform: translateY(-50%);
          cursor: pointer;

          > div {
            height: 2px;
            width: 100%;
            background: #fff;
            position: absolute;
            top: 0;
            left: 0;
            transition: all 0.25s;

            &:nth-child(2) {
              top: 8px;
            }

            &:nth-child(3) {
              top: 16px;
            }
          }
        }
      }
    }

    &.opened {
      > .container > .wrapper {
        ul {
          position: absolute;
          top: 58.5px;
          left: 0;
          width: 100%;
          background: $colorNav;
          display: block;
          text-align: left;
          padding: 15px 20px;

          li {
            display: block;

            > a {
              display: block;
              padding: 7.5px 0;
            }
          }
        }

        .toggle > div {
          &:nth-child(1) {
            top: 8px;
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:nth-child(3) {
            top: 8px;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }

  > article {
    .content {
      .image {
        height: 250px;
        display: block;
        margin: 50px auto 10px auto;
      }
    }
  }

  > footer {
    border-top: 1px solid #ddd;
    padding: 35px 0;

    span {
      display: inline-block;
    }

    .langs {
      margin: 3px 0 0 5px;
      float: right;

      .lang {
        cursor: pointer;
        margin-left: 5px;
        height: 20px;
        width: 30px;
        border: 1px solid rgba(0, 0, 0, 0.125);
        background-size: auto 100%;
        background-position: center;
        background-repeat: no-repeat;

        &.ko {
          background-color: #fff;
        }

        &.en {
          background-color: #012169;
        }
      }
    }

    .copyright {
      float: left;
    }

    .email {
      color: inherit;
      float: right;
    }
  }

  > .climb {
    position: fixed;
    background: rgba(255, 255, 255, 0.75);
    border-radius: 0;
    right: 15px;
    bottom: 15px;
    padding: 0;
    width: 66px;
    height: 66px;
    line-height: 66px;
    text-align: center;
    border: 1px solid;
    box-shadow: none;
    display: none;
    z-index: 10;

    &.on {
      opacity: 1;
    }
  }

  @media (max-width: 767px) {
    > nav {
      > .container > .wrapper {
        position: static;
        padding: 15px 0;

        .brand {
          position: static;
        }

        ul {
          display: none;

          li {
            margin: 0;
          }
        }

        .account {
          display: none;
        }
      }
    }

    > footer {
      text-align: center;

      .copyright {
        float: none;
        margin-bottom: 5px;
      }

      .email {
        float: none;
      }

      > .container {
        .langs {
          margin: 15px 0 7px 0;
          float: right;
          float: none;
        }
      }
    }
  }
}
</style>
