import { createStore } from "vuex";

export default createStore({
  state: {
    video: "",
  },
  mutations: {
    setVideo(state, payload) {
      state.video = payload;
    },
  },
});
