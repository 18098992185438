<template>
  <div class="video" @click="click($event)">
    <div class="container">
      <div class="wrapper">
        <div class="ratio-video">
          <iframe :src="`https://www.youtube.com/embed/${$store.state.video}?autoplay=1`" title="Video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="cls pointer" @click="$store.commit('setVideo', '')">
          <img src="/assets/img/ico.close.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted } from "@vue/runtime-core";
import store from "../store";
export default {
  setup() {
    const func = (e) => {
      if (e.key === "Escape") {
        store.commit("setVideo", "");
      }
    };

    const click = (e) => {
      e.target.classList.contains("video") && store.commit("setVideo", "");
    };

    onMounted(() => {
      document.addEventListener("keyup", func);
    });

    onUnmounted(() => {
      document.removeEventListener("keyup", func);
    });

    return { click };
  },
};
</script>

<style lang="scss" scoped>
.video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.9);
  z-index: 99;

  > .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    > .wrapper {
      position: relative;
      padding-top: 45px;

      .cls {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: -45px;
        color: #fff;
        font-size: 0;
        width: 35px;
        height: 35px;

        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  @media (max-width: 767px) {
    > .container > .wrapper {
      padding-top: 40px;

      .cls {
        margin-right: 0;
        width: 30px;
        height: 30px;
      }
    }

    @media screen and (orientation: landscape) {
      > .container {
        position: absolute;
        top: 0;
        left: 0;
        transform: none;
      }
    }
  }
}
</style>
