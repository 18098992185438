<template>
  <Ground />
</template>

<script>
import Ground from "./components/Ground";

export default {
  name: "App",
  components: {
    Ground,
  },
};
</script>