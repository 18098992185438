import { createWebHistory, createRouter } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/components/Home"),
  },
];

for (let m of ["intro", "lessons", "videos", "documents", "questions"]) {
  routes.push({
    path: `//${m}`,
    name: m,
    component: () => import("@/components/" + m[0].toUpperCase() + m.substring(1)),
  });
}

routes.push({
  path: "/:catchAll(.*)",
  component: () => import("@/components/Home"),
});

export const router = createRouter({
  history: createWebHistory(),
  routes,
});
